import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { ref, getCurrentInstance, onBeforeMount } from "vue";
import { vantLocales } from '../i18n';
import { useI18n } from 'vue-i18n';
export default {
  __name: 'lang',
  setup(__props) {
    const $app = getCurrentInstance().proxy;
    onBeforeMount(() => {
      if ($app.$store.state.vuex_lang === 'en-US') {
        result.value = 'English';
      } else if ($app.$store.state.vuex_lang === 'zh-HK') {
        result.value = '繁體中文';
      }
    });
    const {
      locale
    } = useI18n();
    const result = ref('简体中文');
    const showPicker = ref(false);
    const columns = [{
      text: '简体中文',
      value: 'zh'
    }, {
      text: '繁體中文',
      value: 'zhhk'
    }, {
      text: 'English',
      value: 'en'
    }];
    const onConfirm = ({
      selectedOptions
    }) => {
      let lang = selectedOptions[0]?.value;
      console.log(lang);
      //切换vant组件语言
      vantLocales(lang);
      //切换页面中的语言
      locale.value = lang;
      localStorage.setItem("language", lang);
      result.value = selectedOptions[0]?.text;
      showPicker.value = false;
    };
    return (_ctx, _cache) => {
      const _component_van_field = _resolveComponent("van-field");
      const _component_van_picker = _resolveComponent("van-picker");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_field, {
        modelValue: result.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => result.value = $event),
        "is-link": "",
        readonly: "",
        name: "picker",
        label: _ctx.$t('yu-yan-xuan-ze'),
        placeholder: _ctx.$t('dian-ji-xuan-ze-yu-yan'),
        onClick: _cache[1] || (_cache[1] = $event => showPicker.value = true),
        class: "u-p-0 u-p-tb-10 u-font-16",
        "input-align": "right",
        "label-width": "100px"
      }, null, 8, ["modelValue", "label", "placeholder"]), _createVNode(_component_van_popup, {
        show: showPicker.value,
        "onUpdate:show": _cache[3] || (_cache[3] = $event => showPicker.value = $event),
        round: "",
        position: "bottom",
        "safe-area-inset-bottom": ""
      }, {
        default: _withCtx(() => [_createVNode(_component_van_picker, {
          columns: columns,
          onConfirm: onConfirm,
          onCancel: _cache[2] || (_cache[2] = $event => showPicker.value = false)
        })]),
        _: 1
      }, 8, ["show"])]);
    };
  }
};