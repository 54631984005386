import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-418cf381"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "home"
};
const _hoisted_2 = {
  class: "swiper"
};
const _hoisted_3 = {
  class: "swiper-title"
};
const _hoisted_4 = {
  class: "text-white"
};
const _hoisted_5 = {
  class: "text-white u-font-24"
};
const _hoisted_6 = {
  class: "navbar u-flex u-row-between u-p-tb-10"
};
const _hoisted_7 = {
  class: "text-white"
};
const _hoisted_8 = {
  class: "container"
};
const _hoisted_9 = {
  class: "card u-m-b-20"
};
const _hoisted_10 = {
  class: "u-font-14 u-m-b-4 title"
};
const _hoisted_11 = {
  key: 0,
  class: "u-font-16 text-weight u-search"
};
const _hoisted_12 = {
  key: 1,
  class: "u-font-16 text-weight u-search"
};
const _hoisted_13 = {
  class: "date-item u-m-r-5 u-w-r-45"
};
const _hoisted_14 = {
  class: "u-font-14 u-m-b-4 title"
};
const _hoisted_15 = {
  class: "u-font-16 text-weight u-search"
};
const _hoisted_16 = {
  class: "date-item u-m-l-5 u-w-r-45"
};
const _hoisted_17 = {
  class: "u-font-14 u-m-b-4 title"
};
const _hoisted_18 = {
  class: "u-font-16 text-weight u-search"
};
const _hoisted_19 = {
  class: "u-font-14 u-m-b-4 title"
};
const _hoisted_20 = {
  class: "search-item u-flex u-row-between u-p-tb-6 u-p-lr-8"
};
const _hoisted_21 = {
  class: "u-w-r-33 border-r"
};
const _hoisted_22 = {
  class: "u-font-14 u-m-b-6"
};
const _hoisted_23 = {
  class: "text-weight"
};
const _hoisted_24 = {
  class: "u-w-r-33 u-p-l-10 border-r"
};
const _hoisted_25 = {
  class: "u-font-14 u-m-b-6"
};
const _hoisted_26 = {
  class: "text-weight"
};
const _hoisted_27 = {
  class: "u-w-r-33 u-p-l-10"
};
const _hoisted_28 = {
  class: "u-font-14 u-m-b-6"
};
const _hoisted_29 = {
  class: "text-weight"
};
const _hoisted_30 = {
  class: "but"
};
const _hoisted_31 = {
  class: "u-font-16"
};
const _hoisted_32 = {
  class: "u-font-16 text-weight u-text-center u-p-tb-16 border-b"
};
const _hoisted_33 = {
  class: "u-flex u-row-between u-p-20 border-b"
};
const _hoisted_34 = {
  class: "u-flex u-row-between u-p-20 border-b"
};
const _hoisted_35 = {
  class: "u-flex u-row-between u-p-20 border-b"
};
const _hoisted_36 = {
  class: "u-p-20"
};
const _hoisted_37 = {
  class: "u-font-16"
};
const _hoisted_38 = {
  class: "u-font-16 text-weight u-text-center u-p-tb-16"
};
const _hoisted_39 = {
  class: "u-p-b-20 u-p-lr-20"
};
const _hoisted_40 = {
  key: 0,
  class: "u-p-b-10 u-p-lr-20"
};
const _hoisted_41 = {
  class: "u-p-tb-10"
};
const _hoisted_42 = {
  key: 0
};
const _hoisted_43 = ["onClick"];
const _hoisted_44 = {
  key: 0
};
const _hoisted_45 = {
  key: 1
};
const _hoisted_46 = {
  key: 1
};
const _hoisted_47 = {
  key: 1
};
const _hoisted_48 = {
  class: "u-p-b-10 u-p-lr-20 border-b"
};
const _hoisted_49 = {
  class: "u-flex u-row-between u-w-r-100"
};
const _hoisted_50 = {
  key: 0,
  class: "u-p-t-10"
};
const _hoisted_51 = {
  key: 1,
  class: "u-p-t-10"
};
const _hoisted_52 = {
  class: "u-p-b-10 u-p-lr-20"
};
const _hoisted_53 = {
  class: "u-p-tb-10"
};
const _hoisted_54 = ["onClick"];
const _hoisted_55 = {
  key: 0
};
const _hoisted_56 = {
  key: 1
};
const _hoisted_57 = {
  class: "u-p-20"
};
const _hoisted_58 = {
  key: 0,
  class: "u-p-b-30 border-b"
};
const _hoisted_59 = {
  class: "u-m-t-20"
};
const _hoisted_60 = {
  class: "card wallet u-p-0"
};
const _hoisted_61 = {
  class: "u-flex u-p-10 theme-bg text-white u-font-14"
};
const _hoisted_62 = {
  class: "u-flex u-p-10"
};
const _hoisted_63 = {
  class: "u-flex u-col-center"
};
const _hoisted_64 = {
  class: "u-flex u-col-center"
};
const _hoisted_65 = {
  class: "u-p-tb-0"
};
const _hoisted_66 = {
  class: "u-flex u-col-center"
};
import conf from '../api/config.js';
import lang from '../components/lang.vue';
import { showSuccessToast, showConfirmDialog, showToast } from 'vant';
import { ref, onBeforeMount, getCurrentInstance } from "vue";
export default {
  __name: 'HomeView',
  setup(__props) {
    const $app = getCurrentInstance().proxy;
    onBeforeMount(() => {
      if (!$app.$store.state.vuex_hotel_search.checkInDate) {
        getBookingDate();
      } else {
        let checkInDate = new Date($app.$store.state.vuex_hotel_search.checkInDate).getTime();
        $app.$http.hotel.getBookingDate().then(res => {
          let bookDate = new Date(res.data).getTime();
          if (checkInDate < bookDate) {
            const today = new Date(res.data);
            today.setDate(today.getDate() + 1);
            $app.$store.commit("setBookInDate", res.data);
            $app.$store.commit("setSearch", {
              key: 'checkInDate',
              value: res.data
            });
            $app.$store.commit("setSearch", {
              key: 'checkOutDate',
              value: $app.$utils.formatDate(today, 'yyyy-mm-dd')
            });
          }
        });
      }
    });
    const showDate = ref(false),
      showUser = ref(false),
      showPeople = ref(false),
      showCountry = ref(false),
      location = ref(null),
      locationLoading = ref(false),
      hotHotelList = ref([]),
      searchKey = ref(''),
      searchHotelList = ref([]),
      bannerList = ref([require('../assets/img/banner.png'), require('../assets/img/banner2.png'), require('../assets/img/banner.jpg')]);
    const handleLink = () => {
        window.open('https://t.me/+15102298477');
      },
      getBookingDate = () => {
        $app.$http.hotel.getBookingDate().then(res => {
          if (res.code === 200) {
            const today = new Date(res.data);
            today.setDate(today.getDate() + 1);
            $app.$store.commit("setBookInDate", res.data);
            $app.$store.commit("setSearch", {
              key: 'checkInDate',
              value: res.data
            });
            $app.$store.commit("setSearch", {
              key: 'checkOutDate',
              value: $app.$utils.formatDate(today, 'yyyy-mm-dd')
            });
          }
        });
      },
      handleOrder = () => {
        if (!$app.$store.state.vuex_token) {
          showToast('请先登录');
          return;
        }
        $app.$router.push({
          name: 'order'
        });
      },
      handleSearch = value => {
        searchKey.value = value;
        if (searchKey.value) {
          $app.$http.hotel.getSearchCity(value).then(res => {
            searchHotelList.value = res.data;
          });
        }
      },
      handleHotCity = val => {
        $app.$store.commit("setSearch", {
          key: 'cityName',
          value: val.cnName
        });
        $app.$store.commit("setSearch", {
          key: 'enName',
          value: val.enName
        });
        $app.$store.commit("setSearch", {
          key: 'city',
          value: val.code
        });
        showCountry.value = false;
      },
      handleCountry = () => {
        if (!$app.$store.state.vuex_token) {
          showToast($app.$t('qing-xian-deng-lu'));
          return;
        }
        $app.$http.hotel.getCityList().then(res => {
          if (res.code === 200) {
            hotHotelList.value = res.data['海外'].slice(0, 20);
          }
        });
        showCountry.value = true;
      },
      handleUser = () => {
        showUser.value = true;
        let userId = '';
        if ($app.$store.state.vuex_user) {
          userId = $app.$store.state.vuex_user.userId;
        } else {
          return;
        }
        $app.$http.user.getUserInfo(userId).then(res => {
          if (res.code === 200) {
            $app.$store.commit('setUser', res.data);
          }
        });
      },
      onConfirm = values => {
        const [start, end] = values;
        showDate.value = false;
        $app.$store.commit("setSearch", {
          key: 'dayCount',
          value: $app.$utils.differDate(start, end)
        });
        $app.$store.commit("setSearch", {
          key: 'checkInDate',
          value: $app.$utils.formatDate(start, 'yyyy-mm-dd')
        });
        $app.$store.commit("setSearch", {
          key: 'checkOutDate',
          value: $app.$utils.formatDate(end, 'yyyy-mm-dd')
        });
      },
      getLocation = async () => {
        if (navigator.geolocation) {
          try {
            const position = await navigator.geolocation.getCurrentPosition(position => {
              // 这里可以处理获取到的位置信息，例如提取城市名称等。
              // 以下是一个简单的示例，将位置信息保存到 location 变量中。
              location.value = `纬度：${position.coords.latitude}，经度：${position.coords.longitude}`;
              console.log(location.value);
              let data = {
                longitude: position.coords.longitude,
                latitude: position.coords.latitude
              };
              locationLoading.value = true;
              $app.$http.hotel.getLocationCity(data).then(res => {
                console.log(res);
                if (res.code == 200) {
                  $app.$store.commit("setCity", res.data.city);
                  $app.$store.commit("setCityCode", res.data.code);
                }
                locationLoading.value = false;
              });
            }, error => {
              console.error('无法获取位置：', error);
            }, {
              enableHighAccuracy: true,
              timeout: 20000,
              maximumAge: 0
            } // 配置选项（可选）
            );
          } catch (error) {
            console.error('获取位置信息失败：', error);
          }
        } else {
          console.error('浏览器不支持地理位置 API');
        }
      },
      handleCity = () => {
        if ($app.$store.state.vuex_current_city) {
          $app.$store.commit("setSearch", {
            key: 'cityName',
            value: $app.$store.state.vuex_current_city
          });
          $app.$store.commit("setSearch", {
            key: 'enName',
            value: $app.$store.state.vuex_current_city
          });
          $app.$store.commit("setSearch", {
            key: 'city',
            value: $app.$store.state.vuex_current_code
          });
          showCountry.value = false;
        } else {
          getLocation();
        }
      },
      handleLogout = () => {
        showConfirmDialog({
          title: $app.$t('xi-tong-ti-shi'),
          message: $app.$t('shi-fou-tui-chu-deng-lu')
        }).then(() => {
          $app.$http.user.logout().then(res => {
            showSuccessToast($app.$t('tui-chu-cheng-gong'));
            $app.$store.commit('setToken', null);
            $app.$store.commit('setUser', null);
            $app.$store.commit('setTraceId', null);
            $app.$store.commit("setSearch", {
              key: 'traceId',
              value: null
            });
            showUser.value = false;
          });
        }).catch(() => {});
      },
      closeTag = () => {};
    return (_ctx, _cache) => {
      const _component_van_image = _resolveComponent("van-image");
      const _component_van_swipe_item = _resolveComponent("van-swipe-item");
      const _component_van_swipe = _resolveComponent("van-swipe");
      const _component_van_icon = _resolveComponent("van-icon");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_calendar = _resolveComponent("van-calendar");
      const _component_van_stepper = _resolveComponent("van-stepper");
      const _component_van_popup = _resolveComponent("van-popup");
      const _component_van_search = _resolveComponent("van-search");
      const _component_van_empty = _resolveComponent("van-empty");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_swipe, {
        class: "my-swipe",
        autoplay: 5000,
        height: "375px",
        "indicator-color": "white"
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bannerList.value, (img, i) => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: i
          }, {
            default: _withCtx(() => [_createVNode(_component_van_image, {
              width: "100%",
              height: "375px",
              fit: "cover",
              src: img
            }, null, 8, ["src"])]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      }), _createElementVNode("div", _hoisted_3, [_createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t('gao-duan-jiu-dian')), 1), _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('jie-jia-ri-cu-xiao-huo-dong')), 1)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(_unref(conf).appName), 1), _createElementVNode("div", {
        onClick: handleUser
      }, [_createVNode(_component_van_icon, {
        name: "wap-nav",
        color: "#fff",
        size: "28px"
      })])])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", {
        class: "search u-m-b-10",
        onClick: handleCountry
      }, [_createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('guo-jia-di-qu-0')), 1), _ctx.$store.state.vuex_lang === 'en-US' ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$store.state.vuex_hotel_search.enName), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.$store.state.vuex_hotel_search.cityName), 1))]), _createElementVNode("div", {
        class: "date u-m-b-10 u-flex u-row-between",
        onClick: _cache[0] || (_cache[0] = $event => showDate.value = true)
      }, [_createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.$t('ru-zhu-ri-qi')), 1), _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$store.state.vuex_hotel_search.checkInDate), 1)]), _createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('li-dian-ri-qi')), 1), _createElementVNode("div", _hoisted_18, _toDisplayString(_ctx.$store.state.vuex_hotel_search.checkOutDate), 1)])]), _createElementVNode("div", {
        class: "search u-m-b-10",
        onClick: _cache[1] || (_cache[1] = $event => showPeople.value = !showPeople.value)
      }, [_createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('ru-zhu-ren-shu')), 1), _createElementVNode("div", _hoisted_20, [_createElementVNode("div", _hoisted_21, [_createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('cheng-ren')), 1), _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$store.state.vuex_hotel_search.adultsNum), 1)]), _createElementVNode("div", _hoisted_24, [_createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t('er-tong')), 1), _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$store.state.vuex_hotel_search.childNum), 1)]), _createElementVNode("div", _hoisted_27, [_createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t('ke-fang')), 1), _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$store.state.vuex_hotel_search.rooms), 1)])])]), _createElementVNode("div", _hoisted_30, [_createVNode(_component_van_button, {
        color: _ctx.$style.themeColor,
        block: "",
        onClick: _cache[2] || (_cache[2] = $event => _ctx.$router.push({
          name: 'hotelList'
        }))
      }, {
        default: _withCtx(() => [_createElementVNode("span", _hoisted_31, _toDisplayString(_ctx.$t('sou-suo')), 1)]),
        _: 1
      }, 8, ["color"])])])]), _createVNode(_component_van_calendar, {
        class: "u-popup",
        show: showDate.value,
        "onUpdate:show": _cache[3] || (_cache[3] = $event => showDate.value = $event),
        type: "range",
        onConfirm: onConfirm,
        color: _ctx.$style.themeColor,
        "min-date": new Date(_ctx.$store.state.vuex_bookInDate),
        "safe-area-inset-bottom": "",
        "default-date": [new Date(_ctx.$store.state.vuex_hotel_search.checkInDate), new Date(_ctx.$store.state.vuex_hotel_search.checkOutDate)]
      }, null, 8, ["show", "color", "min-date", "default-date"]), _createVNode(_component_van_popup, {
        show: showPeople.value,
        "onUpdate:show": _cache[7] || (_cache[7] = $event => showPeople.value = $event),
        position: "bottom",
        "safe-area-inset-bottom": "",
        closeable: "",
        round: "",
        class: "u-popup"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.$t('ru-zhu-ren-shu')), 1), _createElementVNode("div", _hoisted_33, [_createElementVNode("div", null, _toDisplayString(_ctx.$t('cheng-ren')), 1), _createVNode(_component_van_stepper, {
          modelValue: _ctx.$store.state.vuex_hotel_search.adultsNum,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.$store.state.vuex_hotel_search.adultsNum = $event),
          theme: "round",
          "button-size": "22",
          "disable-input": ""
        }, null, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_34, [_createElementVNode("div", null, _toDisplayString(_ctx.$t('er-tong')), 1), _createVNode(_component_van_stepper, {
          modelValue: _ctx.$store.state.vuex_hotel_search.childNum,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _ctx.$store.state.vuex_hotel_search.childNum = $event),
          min: "0",
          theme: "round",
          "button-size": "22",
          "disable-input": ""
        }, null, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_35, [_createElementVNode("div", null, _toDisplayString(_ctx.$t('ke-fang')), 1), _createVNode(_component_van_stepper, {
          modelValue: _ctx.$store.state.vuex_hotel_search.rooms,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _ctx.$store.state.vuex_hotel_search.rooms = $event),
          theme: "round",
          "button-size": "22",
          "disable-input": ""
        }, null, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_36, [_createVNode(_component_van_button, {
          color: _ctx.$style.themeColor,
          block: ""
        }, {
          default: _withCtx(() => [_createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.$t('que-ren')), 1)]),
          _: 1
        }, 8, ["color"])])]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_popup, {
        show: showCountry.value,
        "onUpdate:show": _cache[9] || (_cache[9] = $event => showCountry.value = $event),
        position: "bottom",
        "safe-area-inset-bottom": "",
        closeable: "",
        round: "",
        class: "u-popup",
        style: {
          height: '80%'
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.$t('guo-jia-di-qu-0')), 1), _createElementVNode("div", _hoisted_39, [_createVNode(_component_van_search, {
          modelValue: searchKey.value,
          "onUpdate:modelValue": [_cache[8] || (_cache[8] = $event => searchKey.value = $event), handleSearch],
          clearable: "",
          placeholder: _ctx.$t('cheng-shi-ming-zhong-wen-ying-wen-pin-yin')
        }, null, 8, ["modelValue", "placeholder"])]), searchKey.value ? (_openBlock(), _createElementBlock("div", _hoisted_40, [_createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.$t('sou-suo-jie-guo')), 1), searchHotelList.value.length > 0 ? (_openBlock(), _createElementBlock("div", _hoisted_42, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(searchHotelList.value, item => {
          return _openBlock(), _createElementBlock("div", {
            key: item.id
          }, [_createElementVNode("div", {
            class: "u-p-tb-10 border-b",
            onClick: $event => handleHotCity(item)
          }, [_createVNode(_component_van_icon, {
            name: "location",
            size: "18px",
            class: "u-m-r-8"
          }), _ctx.$store.state.vuex_lang === 'en-US' ? (_openBlock(), _createElementBlock("span", _hoisted_44, _toDisplayString(item.enName), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_45, _toDisplayString(item.cnName), 1))], 8, _hoisted_43)]);
        }), 128))])) : (_openBlock(), _createElementBlock("div", _hoisted_46, [_createVNode(_component_van_empty, {
          image: "search",
          description: _ctx.$t('mei-you-sou-suo-dao-jie-guo-huan-ge-ci-shi-shi-ba')
        }, null, 8, ["description"])]))])) : (_openBlock(), _createElementBlock("div", _hoisted_47, [_createElementVNode("div", _hoisted_48, [_createElementVNode("div", _hoisted_49, [_createElementVNode("div", null, _toDisplayString(_ctx.$t('dang-qian-wei-zhi')), 1), _createElementVNode("div", {
          onClick: getLocation
        }, [_createVNode(_component_van_icon, {
          name: "aim",
          size: "18px",
          class: "u-m-r-0"
        })])]), _ctx.$store.state.vuex_current_city ? (_openBlock(), _createElementBlock("div", _hoisted_50, [_createVNode(_component_van_button, {
          size: "small",
          loading: locationLoading.value,
          icon: "location",
          type: "default",
          onClick: handleCity
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$store.state.vuex_current_city), 1)]),
          _: 1
        }, 8, ["loading"])])) : (_openBlock(), _createElementBlock("div", _hoisted_51, [_createVNode(_component_van_button, {
          size: "small",
          loading: locationLoading.value,
          icon: "aim",
          type: "default",
          onClick: getLocation
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('wo-de-wei-zhi')), 1)]),
          _: 1
        }, 8, ["loading"])]))]), _createElementVNode("div", _hoisted_52, [_createElementVNode("div", _hoisted_53, _toDisplayString(_ctx.$t('re-men-sou-suo')), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(hotHotelList.value, item => {
          return _openBlock(), _createElementBlock("div", {
            key: item.id
          }, [_createElementVNode("div", {
            class: "u-p-tb-10 border-b",
            onClick: $event => handleHotCity(item)
          }, [_createVNode(_component_van_icon, {
            name: "location",
            size: "18px",
            class: "u-m-r-8"
          }), _ctx.$store.state.vuex_lang === 'en-US' ? (_openBlock(), _createElementBlock("span", _hoisted_55, _toDisplayString(item.enName), 1)) : (_openBlock(), _createElementBlock("span", _hoisted_56, _toDisplayString(item.cnName), 1))], 8, _hoisted_54)]);
        }), 128))])]))]),
        _: 1
      }, 8, ["show"]), _createVNode(_component_van_popup, {
        show: showUser.value,
        "onUpdate:show": _cache[12] || (_cache[12] = $event => showUser.value = $event),
        position: "right",
        style: {
          width: '280px',
          height: '100%'
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_57, [_ctx.$store.state.vuex_token ? (_openBlock(), _createElementBlock("div", _hoisted_58, [_createElementVNode("div", {
          class: "u-flex",
          onClick: _cache[10] || (_cache[10] = $event => _ctx.$router.push({
            name: 'user'
          }))
        }, [_createVNode(_component_van_icon, {
          name: "user-circle-o",
          size: "30px",
          class: "u-m-r-8"
        }), _createTextVNode(" " + _toDisplayString(_ctx.$store.state.vuex_user.nickName), 1)]), _createElementVNode("div", _hoisted_59, [_createElementVNode("div", _hoisted_60, [_createElementVNode("div", _hoisted_61, [_createVNode(_component_van_icon, {
          name: "envelop-o",
          size: "20px",
          class: "u-m-r-4"
        }), _createTextVNode(" " + _toDisplayString(_ctx.$store.state.vuex_user.email), 1)]), _createElementVNode("div", _hoisted_62, [_createVNode(_component_van_icon, {
          name: "pending-payment",
          size: "20px",
          class: "u-m-r-4"
        }), _createTextVNode(" " + _toDisplayString(_ctx.$store.state.vuex_user.balance || '0.00') + " " + _toDisplayString(_ctx.$store.state.vuex_user.currency), 1)])])])])) : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "u-flex u-col-center u-p-b-30 border-b",
          onClick: _cache[11] || (_cache[11] = $event => _ctx.$router.push({
            name: 'welcome'
          }))
        }, [_createVNode(_component_van_icon, {
          name: "user-circle-o",
          size: "30px",
          class: "u-m-r-8"
        }), _createTextVNode(_toDisplayString(_ctx.$t('wei-deng-lu')), 1)])), _createElementVNode("div", {
          class: "u-p-tb-12",
          onClick: handleOrder
        }, [_createElementVNode("div", _hoisted_63, [_createVNode(_component_van_icon, {
          name: "description",
          size: "22px",
          class: "u-m-r-8"
        }), _createTextVNode(_toDisplayString(_ctx.$t('wo-de-ding-dan')), 1)])]), _createElementVNode("div", {
          class: "u-p-tb-12",
          onClick: handleLink
        }, [_createElementVNode("div", _hoisted_64, [_createVNode(_component_van_icon, {
          name: "service",
          size: "22px",
          class: "u-m-r-8"
        }), _createTextVNode(_toDisplayString(_ctx.$t('lian-xi-ke-fu')), 1)])]), _createElementVNode("div", _hoisted_65, [_createElementVNode("div", _hoisted_66, [_createVNode(_component_van_icon, {
          name: "setting",
          size: "22px",
          class: "u-m-r-8"
        }), _createVNode(lang)])])])]),
        _: 1
      }, 8, ["show"])]);
    };
  }
};