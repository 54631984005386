import request from './request'

// 登录方法
export function login(data) {
  return request({
    url: '/auth/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}
// 用户密码找回
export function retrieve(data) {
  return request({
    url: '/auth/retrievePwd',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}
// 注册方法
export function register(data) {
  return request({
    url: '/auth/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}
// 获取邮箱验证码
export function getEmailCode(email) {
    return request({
      url: '/auth/emailCode',
      headers: {
        isToken: false
      },
      method: 'get',
      params: {email}
    })
}
// 退出方法
export function logout() {
  return request({
    url: '/auth/logout',
    headers: {
      isToken: true
    },
    method: 'post',
  })
}
// 获取用户信息
export function getUserInfo(userId) {
  return request({
    url: '/user/info',
    headers: {
      isToken: true
    },
    method: 'get',
    params: {userId}
  })
}
// 修改用户个人信息
export function editUserInfo(data) {
  return request({
    url: '/user/edit',
    headers: {
      isToken: true
    },
    method: 'put',
    data: data
  })
}
// 修改用户个人信息
export function updatePwd(data) {
  return request({
    url: '/user/updatePwd',
    headers: {
      isToken: true
    },
    method: 'put',
    data: data
  })
}
// 修改用户头像
export function updateAvatar(file) {
  return request({
    url: '/user/updateAvatar/' + file,
    headers: {
      isToken: true
    },
    method: 'post',
  })
}

