import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";


export default createStore({
  state: {
    vuex_token: null,
    vuex_current_city:null,
    vuex_current_code:null,
    vuex_traceId:false,
    vuex_user:{},
    vuex_lang:'zh-CN',
    vuex_groups:{},
    vuex_bookInDate:null,
    vuex_hotel_search:{
      traceId: null,
      cityName: "胡志明市",
      enName:'Ho Chi Minh City',
      city: "SGN",
      checkInDate: null,
      checkOutDate: null,
      dayCount: 1,
      adultsNum: 2,
      childNum: 0,
      rooms: 1,
      blocId: "",
      blocAll: "", //集团名
      brandId: "",
      brandName: "",
      hotelChains: "",
    }
  },
  getters: {},
  mutations: {
    setBookInDate(state,playload){
      state.vuex_bookInDate = playload
    },
    setCity(state,playload){
      state.vuex_current_city = playload
    },
    setCityCode(state,playload){
      state.vuex_current_code = playload
    },
    setToken(state, payload) {
      state.vuex_token = payload;
    },
    setLang(state, payload) {
      state.vuex_lang = payload;
    },
    setTraceId(state, payload) {
      state.vuex_traceId = payload;
    },
    setUser(state, payload) {
      state.vuex_user = payload;
    },
    setGroups(state, payload) {
      state.vuex_groups = payload;
    },
    setSearch(state, payload) {
      state.vuex_hotel_search[payload.key] = payload.value;
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      // 存储方式：localStorage、sessionStorage、cookies  默认: localStorage
      storage: window.localStorage,
      // 存储的 key 的key值
      key: "vuex",
      render(state) {
        // 要存储的数据：本项目采用es6扩展运算符的方式存储了state中所有的数据
        return { ...state };
      }
    })
  ]
});
