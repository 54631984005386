import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/welcome",
    name: "welcome",
    component: () =>
      import("../views/Welcome.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import("../views/Login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () =>
      import("../views/Register.vue"),
  },
  {
    path: "/user",
    name: "user",
    component: () =>
      import("../views/User.vue"),
  },
  {
    path: "/order",
    name: "order",
    component: () =>
      import("../views/Order.vue"),
  },
  {
    path: "/orderDetail",
    name: "orderDetail",
    component: () =>
      import("../views/Order_detail.vue"),
  },
  {
    path: "/hotel_list",
    name: "hotelList",
    component: () =>
      import("../views/Hotel_list.vue"),
  },
  {
    path: "/hotel_detail",
    name: "hotelDetail",
    component: () =>
      import("../views/Hotel_detail.vue"),
  },
  {
    path: "/hotel_book",
    name: "hotelBook",
    component: () =>
      import("../views/Hotel_book.vue"),
  },
  {
    path: "/booking",
    name: "booking",
    component: () =>
      import("../views/Booking.vue"),
  },
  {
    path: "/result",
    name: "result",
    component: () =>
      import("../views/Result.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
