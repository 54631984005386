export default {
	// 本地测试环境
	// apiHost: "http://192.168.1.199:6080",
	// apiHost: "http://192.168.1.3:6080",
	// apiHost: "https://api.hotel711.com",
	apiHost: "https://api.gas.travel",
	// 租户ID
	xtenant: 'd57e63f9e7a543c89a8bec1a7da02b44',
    // API接口地址
    // apiHost: "https://hm.api.8973club.com",

	//小程序名字
	// appName:'HOTEL711',
	appName: 'GAS Travel',
	// apiHost: "https://hm.api.zhixinglux.com",
	//文件地址
	letterUrl: "https://hm.cos.8973club.com/letter/",
	// 图片地址
	imgUrl: "https://hm.cos.8973club.com/image",
	//银行图标地址
	bankUrl: "https://hm.cos.8973club.com/banklogo",
};