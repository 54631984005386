import request from './request'

// 根据经纬度获取地址信息
export function getLocationCity(data) {
  return request({
    url: '/hotel/location/city',
    headers: {
      isToken: true
    },
    method: 'get',
    params: data
  })
}
// 查询热门城市列表
export function getCityList() {
  return request({
    url: '/hotel/city/hot/list',
    headers: {
      isToken: true
    },
    method: 'get',
  })
}
// 城市搜索
export function getSearchCity(key) {
  return request({
    url: `/hotel/city/search/${key}`,
    headers: {
      isToken: true
    },
    method: 'get'
  })
}
// 获取酒店列表
export function getHotelList(data) {
  return request({
    url: '/hotel/list',
    headers: {
      isToken: true
    },
    method: 'get',
    params: data
  })
}
// 实时查询酒店列表价格
export function getHotelListPrice(data) {
  return request({
    url: '/hotel/list/price',
    headers: {
      isToken: true
    },
    method: 'get',
    params: data
  })
}
// 获取酒店详情
export function getHotel(data) {
  return request({
    url: '/hotel/detail/' + data,
    headers: {
      isToken: true
    },
    method: 'get'
  })
}
// 获取酒店房间列表
export function getHotelRoomList(data) {
  return request({
    url: '/hotel/room/list',
    headers: {
      isToken: true
    },
    method: 'get',
    params: data
  })
}
// 获取酒店房间详情
export function getHotelRoomDeatil(data) {
  return request({
    url: '/hotel/room/detail',
    headers: {
      isToken: true
    },
    method: 'get',
    params: data
  })
}
// 预定页获取酒店房间房间价格信息
export function getHotelRoomRateInfo(data) {
  return request({
    url: '/hotel/room/rate/info',
    headers: {
      isToken: true
    },
    method: 'get',
    params: data
  })
}
// 创建酒店订单
export function bookOreder(data) {
  return request({
    url: '/hotel/booking/create',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}
// 查询酒店预定订单列表
export function getBookingList(data) {
  return request({
    url: '/hotel/booking/list',
    headers: {
      isToken: true
    },
    method: 'get',
    params: data
  })
}
// 获取酒店预定订单详细信息
export function getBookingInfo(id) {
  return request({
    url: `/hotel/booking/info/${id}`,
    headers: {
      isToken: true
    },
    method: 'get',
  })
}
// 取消酒店订单
export function cancelBooking(id) {
  return request({
    url: `/hotel/booking/cancel/${id}`,
    headers: {
      isToken: true
    },
    method: 'delete',
  })
}
// 获取酒店预定订单详细信息
export function getBookingDate() {
  return request({
    url: `/hotel/startBookingDate`,
    headers: {
      isToken: true
    },
    method: 'get',
  })
}