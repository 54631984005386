import axios from 'axios';
import store from "../store";
import router from "../router";
import conf from "./config.js";
import { showToast,showConfirmDialog  } from 'vant';

let apiHost = conf.apiHost + '/api'

// 创建一个Axios实例
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
  baseURL: apiHost, // 设置请求的基础URL
  headers:{
    "content-type": "application/json;charset=UTF-8",
    "X-TENANT": "d57e63f9e7a543c89a8bec1a7da02b44",
    "Accept-Language": "zh"
  },
  timeout: 180000, // 设置请求超时时间
});

// 设置请求拦截器
service.interceptors.request.use(
    (config) => {
        if (store.state.vuex_lang) config.headers['Accept-Language'] = store.state.vuex_lang
        if(config.headers.isToken){
            if (store.state.vuex_token) config.headers.Authorization = "Bearer " + store.state.vuex_token
        }
        return config;
    },
    (error) => {
        // 处理响应错误
        return Promise.reject(error);
    }

)

// 设置响应拦截器
service.interceptors.response.use(
    (res) => {
        if(res.data.code == 200){
            return res.data;
        } else if(res.data.code == 401){
            showToast('验证失败，请重新登录')
            store.commit('setToken',null)
            store.commit('setUser',null)
            store.commit('setTraceId',null)
            router.push({name:'login'})
            return res.data
        } else if (res.data.code == 301){
            showConfirmDialog({
                title: '提示',
                message: res.data.msg,
                showCancelButton: false,
            })
            .then(() => {
                // on confirm
                router.push({name:'hotel_list'})
            })
            return res.data
        } else {
            showToast(res.data.msg)
            return res.data
        }
    },
    (error) => {
        // 处理响应错误
        return Promise.reject(error);
    }

)
export default service;