// 日期格式化 "2024-01-06T06:53:03.000000Z" => yyyy-mm-dd
export function parseDate (isoString) {  
    var date = new Date(isoString);  
    var year = date.getFullYear();  
    var month = ("0" + (date.getMonth() + 1)).slice(-2); // getMonth() is zero-based, so we add 1 and then pad with "0"  
    var day = ("0" + date.getDate()).slice(-2); // same with getDate()  
    return year + "-" + month + "-" + day;  
} 
export function formatDate(val, format) {  
    const pad = (n, width, z = '0') => (String(n).length >= width ? String(n) : new Array(width - String(n).length + 1).join(z) + n);  
    let date = new Date(val)
    const getYear = () => date.getFullYear();  
    const getMonth = () => pad(date.getMonth() + 1, 2);  
    const getDay = () => pad(date.getDate(), 2);  
    const getHours = () => pad(date.getHours(), 2);  
    const getMinutes = () => pad(date.getMinutes(), 2);  
    const getSeconds = () => pad(date.getSeconds(), 2);  
    const getWeekday = () => date.toLocaleString('en-us', { weekday: 'short' }).toUpperCase();  
    
    return format  
      .replace(/yyyy/g, getYear())  
      .replace(/mm/g, getMonth())  
      .replace(/dd/g, getDay())  
      .replace(/hh/g, getHours())  
      .replace(/ii/g, getMinutes())  
      .replace(/ss/g, getSeconds())  
      .replace(/ww/g, getWeekday()); // 假设格式字符串中使用 'ww' 来表示星期几的缩写  
}  
// 计算时间差
export function differDate(startDate, endDate) {
    if (startDate && endDate) {
        if (typeof startDate == "string") {
          startDate = startDate.replace(/-/g, "/");
        }
        if (typeof endDate == "string") {
          endDate = endDate.replace(/-/g, "/");
        }
        let start_date = new Date(startDate).getTime();
        let end_date = new Date(endDate).getTime();
        let differ = end_date - start_date;
        //计算出相差天数
        let days = Math.floor(differ / (24 * 3600 * 1000));
        //计算出小时数
        let leave1 = differ % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
        let hours = Math.floor(leave1 / (3600 * 1000));
        //计算相差分钟数
        let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
        let minutes = Math.floor(leave2 / (60 * 1000));
        return days;
        // return (
        //   (days > 0 ? days + "天" : "") +
        //   (hours > 0 ? hours + "小时" : "") +
        //   minutes +
        //   "分钟"
        // );
    }
    return "";
}

export function currencyFormat(val) {
    if (!val) return '￥';
    let c = val.replace(/-?([0-9]\d*(\.\d*)*|0\.[0-9]\d*)/g, '');
    if ('CNY' === c) {
        return '￥'
    }
    return c;
}
// 金额千分位格式化
export function NumFormat(value) {
    if (!value) return '0';
  
    var numArr = value.toString().match(/-?(\d+(\.\d*)?|\.\d+)/g);
    value = numArr.join('');
  
    var intPart = parseInt(value);
    var intPartFormat = intPart.toLocaleString();
  
    var decimalPart = "";
    var valueArray = value.split(".");
    if (valueArray.length == 2) {
      decimalPart = "." + valueArray[1];
    }
  
    return intPartFormat + decimalPart;
}
// 获取订单状态
export function getOrderStatus(val) {
  if(val === 0){
    return '待支付'
  }else if(val === 1){
    return '已支付'
  }else if(val === 2){
    return '待入住'
  }else if(val === 3){
    return '待确认'
  }else if(val === 4){
    return '已完成'
  }else if(val === 5){
    return '已取消'
  }else if(val === 6){
    return '预定异常'
  }
}
// 获取付款状态
export function getPayStatus(val) {
  if(val === 0){
    return '待支付'
  }else if(val === 1){
    return '已支付'
  }else if(val === 2){
    return '已取消'
  }
}
// 获取付款方式
export function getPayType(val) {
  if(val === 0){
    return '区块链支付'
  }else if(val === 1){
    return '余额支付'
  }else if(val === 2){
    return '组合支付'
  }
}