import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { showToast,showDialog,showNotify,showImagePreview } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import 'vant/es/notify/style';
import 'vant/es/image-preview/style';
import '@vant/touch-emulator';
import { style } from "./utils/style";
import * as utils from "./utils/index";
import {i18n, vantLocales} from './i18n'
import http from "./api/index";
import config from "./api/config";
vantLocales(i18n.locale)

const myApp = createApp(App);
myApp.use(store);
myApp.use(router);
myApp.use(i18n);
myApp.config.globalProperties.$style = style
myApp.config.globalProperties.$utils = utils
myApp.config.globalProperties.showToast = showToast
myApp.config.globalProperties.showDialog = showDialog
myApp.config.globalProperties.showNotify = showNotify
myApp.config.globalProperties.showImagePreview = showImagePreview
myApp.config.globalProperties.$http = http
myApp.config.globalProperties.$config = config

myApp.mount("#app");
